<template>
    <div>
       <h1>ຍິນດີຕ້ອນຮັບ ທ່ານເຂົ້ານຳໃຊ້ລະບົບ</h1>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>